.text-center{ text-align: center; }
.text-right{ text-align: right; }
.text-left{ text-align: left; }
.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.pl10{ padding-left: 10px; }
.pl20{ padding-left: 20px; }
.pl30{ padding-left: 30px; }
.pl40{ padding-left: 40px; }
.pl50{ padding-left: 50px; }
.pr10{ padding-right: 10px; }
.pr20{ padding-right: 20px; }
.pr30{ padding-right: 30px; }
.pr40{ padding-right: 40px; }
.pr50{ padding-right: 50px; }

.w100{ width: 100%; }

.show{
  &-sp{
    display: none;
    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }
  &-pc{
    display: block;
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }
}

.btn{
  &-center{
    a{
      margin: 0 auto;
    }
  }
  &-under{
    &-arrow{
      display: block;
      border-bottom: 1px solid #FFF;
      position: relative;
      &::after{
        position: absolute;
        right: 0;
        bottom: -1px;
        content: '';
        width: 10px;
        height: 10px;
        border-bottom: solid 1px;
        border-right: solid 1px;
        transform: skew(45deg);
      }
      &:hover{
        opacity: .8;
      }
    }
  }
  &-square{
    &-arrow{
      display: block;
      background: $color-white;
      border: 1px solid $color-white;
      position: relative;
      color: #000 !important;
      text-align: center;
      padding: getVW-base(20) 10%  getVW-base(20) 0;
      font-size: getPercentFontSize-base(22);
      font-weight: bold;
      &:visited{
        color: #000 !important;
      }
      &::before { /* 三角形の表示設定 */
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 6%; 
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid #000;
      }
      &:hover{
        color: $color-white !important;
        background: $color-main;
        &::before{
          border-left: 10px solid $color-white;
        }
      }
      &.w-m{
        width: getVW-base(420);
        padding: getVW-base(20) 0  getVW-base(20) 0;
      }
      @media screen and (max-width: $breakpoint-lg){
        padding: getVW-base-sp(16) 0 getVW-base-sp(16) 0;
        font-size: getPercentFontSize-base(18);
        &.w-m{
          width: 100%;
          padding: getVW-base-sp(10) 0 getVW-base-sp(10) 0;
        }
      }

    }
  }
}
