@import 'settings';
@import 'mixin';
@import 'reset';
@import 'module';

@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

*{

}
html,body{
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
  font-weight: 500;
  height: 100%;
  line-height: 1.6;
  letter-spacing: 0;
  font-size: getVW-base($main-base-fontsize);
  @media screen and (max-width: $breakpoint-lg){
    font-size: getVW-base-sp($main-base-fontsize);
  }
}
body{
  height: 100%;
  a {
    transition: .2s ease;
    text-decoration: none;
  }
  @media screen and (max-width: $breakpoint-lg){
  }
}

.wrap{
  height: 100%;
}
.gnav-bg{
  position: fixed;
  top:0;
  left: 0;
  width: calc(400 / 1920 * 100vw);
  // min-width: 400px;
  //background: rgba(#09030B, .8);
  min-height: 600px;
  height: 100%;
  background: #0C346A;
  mix-blend-mode: multiply;
  @media screen and (max-width: $breakpoint-lg){
    display: none;
  }
}
.gnav{
  position: fixed;
  top:0;
  left: 0;
  width: calc(400 / 1920 * 100vw);
  // min-width: 400px;
  //background: rgba(#09030B, .8);
  min-height: 600px;
  height: 100%;
  color: $color-white;
  // background: #0C346A;
  // mix-blend-mode: multiply;
  // &:before {
  //     content: "";
  //     display: block;
  //     width: 100%;
  //     height: 100%;
  //     background: #0C346A;
  //     mix-blend-mode: multiply;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //   }
  a, a:visited{
    color: $color-white;
  }
  &__bg{
    position: relative;
    width: 100%;
    height: 100%;
    padding: getVW-base(60);
    // background: #0C346A;
    // mix-blend-mode: multiply;
    // &:before {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   background: #0C346A;
    //   mix-blend-mode: multiply;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }
  &__innr{
    position: relative;
    height: 100%;
  }
  &__ttl{
    margin-bottom: getVW-base(60);
    img{
      width: getVW-base(271);
    }
  }
  &__date{
    margin-bottom: getVW-base(60);
    font-size: getPercentFontSize-base(38);
    line-height: 1.2;
    font-weight: bold;
    span{
      font-size: 64%;
    }
  }
  &__info{
    font-size: getPercentFontSize-base(20);
    font-weight: bold;
    line-height: 2.2;
  }
  &__menu{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    &__list{
      margin-bottom: getVW-base(60);
      &__item{
        font-size: getPercentFontSize-base(22);
        margin-bottom: getVW-base(34);
      }
    }
    &__link{
      p{
        margin-top: getVW-base(18);
      }
    }
  }
  @media screen and (max-width: $breakpoint-lg){
    background: #0C346A;
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s;
    left: auto;
    right: -100%;
    &.panelactive{
      display: block;
      visibility: visible;
      opacity: 1;
      z-index: 99;
      width: 100vw;
      height: 100vh;
      right: 0;
    }
    &__bg{
      padding: getVW-base-sp(66) getVW-base-sp(50);
      &:before {
      }
    }
    &__innr{
      position: relative;
      height: 100%;
    }
    &__ttl{
      margin-bottom: getVW-base-sp(20);
      img{
        width: getVW-base-sp(217);
      }
    }
    &__date{
      margin-bottom: getVW-base-sp(20);
      font-size: getPercentFontSize-base(23);
      line-height: 1.8;
      font-weight: bold;
      span{
        font-size: 64%;
      }
    }
    &__info{
      font-size: getPercentFontSize-base(16);
      font-weight: bold;
      line-height: 2;
    }
    &__menu{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      &__list{
        margin-bottom: getVW-base-sp(30);
        &__item{
          font-size: getPercentFontSize-base(22);
          margin-bottom: getVW-base-sp(20);
        }
      }
      &__link{
        p{
          margin-top: getVW-base-sp(15);
          font-size: getPercentFontSize-base(18);
          width: 100%;
        }
      }
    }

  }
  @media (max-width: $breakpoint-lg) and (max-height: 700px) {
    &__bg{
      padding: getVW-base-sp(40) getVW-base-sp(30);
    }
    &__innr{
      position: relative;
      height: 100%;
    }
    &__ttl{
      margin-bottom: getVW-base-sp(15);
      img{
        width: getVW-base-sp(200);
      }
    }
    &__date{
      margin-bottom: getVW-base-sp(15);
      font-size: getPercentFontSize-base(20);
      line-height: 1.6;
      font-weight: bold;
      span{
        font-size: 64%;
      }
    }
    &__info{
      font-size: getPercentFontSize-base(16);
      font-weight: bold;
      line-height: 1.6;
    }
    &__menu{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      &__list{
        margin-bottom: getVW-base-sp(20);
        &__item{
          font-size: getPercentFontSize-base(20);
          margin-bottom: getVW-base-sp(15);
        }
      }
      &__link{
        p{
          margin-top: getVW-base-sp(10);
          font-size: getPercentFontSize-base(16);
          width: 100%;
        }
      }
    }
  }
 
}
.gnav-btn{
  display: none;
  @media screen and (max-width: $breakpoint-lg) {
    display: block;
    position: fixed;
    top: getVW-base-sp(20);
    right: getVW-base-sp(20);
    z-index: 100;
    img{
      width: getVW-base-sp(40);
    }
    &__open{
      display: block;
    }
    &__close{
      display: none;
    }
    &.panelactive{
      .gnav-btn__open{
        display: none;
      }
      .gnav-btn__close{
        display: block;
      }
    }

  }
}

.cts{
  margin-left: calc(400 / 1920 * 100vw);
  padding: getVW-base(120) 0 getVW-base(120) getVW-base(50);
  &.lower{
    padding: getVW-base(120) 0 getVW-base(120) getVW-base(100);
  }
  &__innr{
    display: flex;
  }
  &__ttl{
    width: getVW-base(250);
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: getVW-base(112);
      width: auto;
      height: getVW-base(112);
      transform:rotateZ(90deg);
      transform-origin:top left;
    }
  }
  &__body{
    flex: 1;
    &__innr{
      padding-right: getVW-base(100);
      &.rightspace0{
        padding-right: 0;
      }
    }
  }
  @media screen and (max-width: $breakpoint-lg){
    margin-left: 0;
    padding: getVW-base-sp(40) getVW-base-sp(20);
    &.lower{
      padding: getVW-base-sp(60) getVW-base-sp(20);
    }
    &__innr{
      display: block;
    }
    &__ttl{
      width: auto;
      position: static;
      margin-bottom: getVW-base-sp(40);
      img{
        position: static;
        width: auto;
        height: getVW-base-sp(35);
        transform:rotateZ(0deg);
      }
    }
    &__body{
      &__innr{
        padding-right: 0;
      }
    }
  }
}

.mv{
  background-color: $color-main;
  height: 100%;
  &.top{
    background: url(../img/bg_mv.jpg) no-repeat center center;
    background-size: cover;
  }
  .cts{
    height: 100%;
  }
  &__innr{
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__ttl{
    img{
      width: getVW-base(1112);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__info{
    display: none;
  }
  @media screen and (max-width: $breakpoint-lg) {

    &.top{
      background: url(../img/bg_mv_sp.jpg) no-repeat center center;
      background-size: cover;
    }
    &__ttl{
      img{
        width: 100vw;
        position: absolute;
        left: 50%;
        top: 10%;
        transform: translate(-50%, 0%);
      }
    }
    &__info{
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      color: $color-white;
      &__ttl{
        margin-bottom: getVW-base-sp(10);
      }
      &__date{
        font-size: getPercentFontSize-base(27);
        span{
          font-size: 60%;
        }
      }
      &__data{
        font-size: getPercentFontSize-base(18);
      }
    }
  }
}

.about{
  background-color: $color-main;
  &__ttl{
    img{
      width: getVW-base(446);
      height: auto;
      margin-bottom: getVW-base(100);
    }
  }
  &__txt{
    font-size: getPercentFontSize-base(27);
    line-height: 1.8;
    color: $color-white;
    letter-spacing: 0em;
    margin-bottom: getVW-base(100);
  }
  @media screen and (max-width: $breakpoint-lg){
    &__ttl{
      img{
        width: getVW-base-sp(291);
        margin-bottom: getVW-base-sp(40);
      }
    }
    &__txt{
      font-size: getPercentFontSize-base(20);
      margin-bottom: getVW-base-sp(40);
    }
  }
}

.program{
  &__ttl{
    display: inline-block;
    border-top: getVW-base(19) solid $color-main;
    padding: getVW-base(42) getVW-base(5) 0;
    margin-bottom: getVW-base(60);
    color: $color-main;
    img{
      width: getVW-base(387);
    }
  }
  &__schedule{
    margin-bottom: getVW-base(100);
    &__list{
      &__item{
        width: getVW-base(420) !important;
        margin-right: getVW-base(50);
        cursor: grab;
        &:active{
          cursor: grabbing;
        }
        &__date{
          font-size: getPercentFontSize-base(24);
          font-weight: bold;
          margin-bottom: getVW-base(20);
        }
        &__ttl{
          font-size: getPercentFontSize-base(30);
          font-weight: bold;
          height: getVW-base(170);
        }
        &__txt{
          height: getVW-base(190);
        }
        &__speaker{
          margin-bottom: getVW-base(20);
          img{
            width: getVW-base(103);
          }
        }
        &__profile{
          display: flex;
          margin-bottom: getVW-base(20);
          &__th{
            width: getVW-base(200);
            img{
              width: 100%;
            }
          }
          &__name{
            padding: 0 0 0 getVW-base(20);
            font-size: getPercentFontSize-base(20);
            flex: 1;
            span{
              display: inline-block;
              font-size: 80%;
              line-height: 1.4;
              margin-bottom: 3px;
            }
          }
          &__place{
            font-size: getPercentFontSize-base(16);
            display: inline-block;
            padding: 0 5px;
            color: $color-white;
            background: $color-main;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-lg){
    &__ttl{
      display: inline-block;
      border-top: getVW-base-sp(19) solid $color-main;
      padding: getVW-base-sp(28) getVW-base-sp(5) 0;
      margin-bottom: getVW-base-sp(30);
      margin-left:  getVW-base-sp(-20);
      img{
        width: getVW-base-sp(278);
      }
    }
    &__schedule{
      margin-bottom: getVW-base-sp(76);
      &__list{
        &__item{
          width: getVW-base-sp(290) !important;
          margin-right: getVW-base-sp(20);
          &__date{
            font-size: getPercentFontSize-base(18);
            margin-bottom: getVW-base(20);
          }
          &__ttl{
            font-size: getPercentFontSize-base(22);
            height: getVW-base-sp(130);
          }
          &__txt{
            font-size: getPercentFontSize-base(18);
            height: getVW-base-sp(220);
          }
          &__speaker{
            margin-bottom: getVW-base-sp(10);
            img{
              width: getVW-base-sp(90);
            }
          }
          &__profile{
            margin-bottom: getVW-base-sp(15);
            &__th{
              width: getVW-base-sp(130);
            }
            &__name{
              padding: 0 0 0 getVW-base-sp(20);
              font-size: getPercentFontSize-base(12);
              span{
                display: inline-block;
                font-size: 80%;
              }
            }
            &__place{
              font-size: getPercentFontSize-base(16);
              display: inline-block;
              padding: 3px 8px;
              color: $color-white;
              background: $color-main;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}

.news{
  background: url(../img/bg_news.jpg) no-repeat center center;
  background-size: cover;
  &__list{
    @include clearfix();
    &-wrap{
      margin-bottom: getVW-base(100);
    }
    &__item{
      width: getVW-base(420) !important;
      margin-right: getVW-base(50);
      float: left;
      cursor: grab;
      a, a:visited{
        color: $color-white;
      }
      &:active{
        cursor: grabbing;
      }
      &__th{
        margin-bottom: getVW-base(30);
        img{
          width: 100%;
          vertical-align: bottom;
        }
      }
      &__ttl{

      }
    }
    @media screen and (max-width: $breakpoint-lg){
      &-wrap{
        margin-bottom: getVW-base-sp(40);
      }
      &__item{
        width: getVW-base-sp(290) !important;
        margin-right: getVW-base-sp(20);
        float: left;
        cursor: grab;
        &__th{
          margin-bottom: getVW-base-sp(26);
          img{
            width: 100%;
          }
        }
        &__ttl{
          p{
            font-size: getPercentFontSize-base(18);
          }
        }
      }
    }
  }
}

.footer{
  background: url(../img/bg_footer.jpg) no-repeat center center;
  background-size: cover;
  color: $color-white;
  .cts{
    padding-bottom: getVW-base(80);
  }
  &__body{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__ttl{
    font-size: getPercentFontSize-base(30);
    font-weight: bold;
    margin-bottom: getVW-base(80);
  }
  &__logo{
    margin-bottom: getVW-base(60);
    img{
      width: getVW-base(88);
    }
  }
  &__txt-about{
    margin-bottom: getVW-base(60);
  }
  &__txt-dips{
    margin-bottom: getVW-base(100);
    width: getVW-base(640);
    line-height: 2;
  }
  &__txt-sponcor{
    text-align: center;
  }
  @media screen and (max-width: $breakpoint-lg){
    .cts{
      padding-bottom: getVW-base-sp(65);
    }
    &__ttl{
      font-size: getPercentFontSize-base(20);
      font-weight: bold;
      margin-bottom: getVW-base-sp(40);
    }
    &__logo{
      margin-bottom: getVW-base-sp(40);
      img{
        width: getVW-base-sp(88);
      }
    }
    &__txt-about{
      font-size: getPercentFontSize-base(16);
      margin-bottom: getVW-base(60);
    }
    &__txt-dips{
      font-size: getPercentFontSize-base(16);
      margin-bottom: getVW-base-sp(40);
      width: getVW-base-sp(309);
      line-height: 2;
    }
    &__txt-sponcor{
      font-size: getPercentFontSize-base(16);
    }
  }
}

.lower-about{
  &__mv{
    color: $color-white;
    height: auto !important;
    &__wrap{

    }
    &__ttl{
      font-size: getPercentFontSize-base(80);
      margin-bottom: getVW-base(80);
    }
    p{
      font-size: getPercentFontSize-base(30);
      line-height: 1.8;
      margin-bottom: 2.4rem;
      font-weight: bold;
    }
    @media screen and (max-width: $breakpoint-lg){
      &__ttl{
        font-size: getPercentFontSize-base(40);
        margin-bottom: getVW-base-sp(30);
      }
      p{
        font-size: getPercentFontSize-base(17);
        line-height: 1.8;
        margin-bottom: 1.6rem;
        font-weight: bold;
      }
    }
  }
  &__cts{
    border-bottom: 1px solid #D4D9D9;
    p{
      line-height: 1.8;
    }
    &__ttl{
      margin-bottom: getVW-base(60);
      img{
        vertical-align: bottom;
      }
    }
    &__subttl{
      margin-bottom: getVW-base(60);
    }
    @media screen and (max-width: $breakpoint-lg){
      p{
        line-height: 1.8;
      }
      &__ttl{
        margin-bottom: getVW-base-sp(50);
      }
      &__subttl{
        margin-bottom: getVW-base-sp(20);
      }
    }
  }
  &__try{
    &__ttl{
      img{
        width: getVW-base(446);
      }
    }
    &__txt1{
      line-height: 2 !important;
      margin-bottom: getVW-base(60);
    }
    &__txt2{
      line-height: 2 !important;
      display: inline-block;
      text-align: left;
    }
    @media screen and (max-width: $breakpoint-lg){
      &__ttl{
        img{
          width: getVW-base-sp(221);
        }
      }
      &__txt1{
        margin-bottom: getVW-base-sp(40);
        font-size: getPercentFontSize-base(16)
      }
      &__txt2{
        line-height: 2 !important;
        display: inline-block;
        text-align: right;
        font-size: getPercentFontSize-base(13)
      }
    }
  }
  &__watch{
    &__ttl{
      img{
        width: getVW-base(571);
        @media screen and (max-width: $breakpoint-lg){
          width: getVW-base-sp(292);
        }
      }
    }
    &__list{
      padding-left: 1.5rem;
      &__item{
        
        list-style-type: decimal;
        list-style-position: outside;

        font-size: getPercentFontSize-base(30);
        margin-bottom: getVW-base(40);
        font-weight: bold;
        &:last-child{
          margin-bottom: getVW-base(0);
        }
        span{
          margin-top: getVW-base(5);
          display: block;
          font-size: 66%;
          font-weight: normal;
        }
      }
      @media screen and (max-width: $breakpoint-lg){
        padding-left: 1.3rem;
        &__item{
          font-size: getPercentFontSize-base(20);
          margin-bottom: getVW-base-sp(20);
          span{
            margin-top: getVW-base-sp(5);
            font-size: 80%;
          }
        }
      }
    }
  }
  &__archive{
    &__ttl{
      img{
        width: getVW-base(331);
        @media screen and (max-width: $breakpoint-lg){
          width: getVW-base-sp(207);
        }
      }
    }
    &__subttl{
      img{
        width: getVW-base(134);
        @media screen and (max-width: $breakpoint-lg){
          width: getVW-base-sp(69);
        }
      }
    }
    .news__list-wrap.mb0{
      margin-bottom: 0 !important;
    }
    .news__list__item{
      a{
        color: #000 !important;
        &:visited{
          color: #000 !important;
        }
      }
      &__th{
        @media screen and (max-width: $breakpoint-lg){
          margin-bottom: getVW-base-sp(20);
        }
      }
      &__ttl{
        p{
          @media screen and (max-width: $breakpoint-lg){
            font-size: getPercentFontSize-base(16);
          }
        }
      }
    }

    
  }
  &__background{
    &__ttl{
      img{
        width: getVW-base(530);
        @media screen and (max-width: $breakpoint-lg){
          width: getVW-base-sp(331);
        }
      }
    }
    &__subttl{
      font-size: getPercentFontSize-base(30);
      margin-bottom: getVW-base(50);
      @media screen and (max-width: $breakpoint-lg){
        font-size: getPercentFontSize-base(24);
        margin-bottom: getVW-base-sp(40);
      }
    }
    &__txt{
      margin-bottom: getVW-base(60);
      @media screen and (max-width: $breakpoint-lg){
        font-size: getPercentFontSize-base(16);
        margin-bottom: getVW-base-sp(50);
      }
    }
    &__col{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: getVW-base(100);
      &:last-child{
        margin-bottom: 0;
      }
      &__txt{
        width: getVW-base(400);
        &__ttl{
          font-size: getPercentFontSize-base(30);
          font-weight: normal;
          margin-bottom: getVW-base(50);
        }
        &__body{

          margin-bottom: getVW-base(50);
        }
        &__source{
          font-size: getPercentFontSize-base(14);
        }
      }
      &__graph{
        img{
          width: getVW-base(869);
          &.graph2{
            width: getVW-base(843);
          }
        }
      }
      @media screen and (max-width: $breakpoint-lg){
        display: block;
        margin-bottom: getVW-base-sp(60);
        &__txt{
          width: 100%;
          &__ttl{
            font-size: getPercentFontSize-base(24);
            font-weight: normal;
            margin-bottom: getVW-base-sp(30);
          }
          &__body{
            font-size: getPercentFontSize-base(16);
            margin-bottom: getVW-base-sp(30);
          }
          &__source{
            font-size: getPercentFontSize-base(12);
          }
        }
        &__graph{
          margin-bottom: getVW-base-sp(20);
          img{
            width: 100%;
            &.graph2{
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__joinus{
    &__ttl{
      img{
        width: getVW-base(316);
        @media screen and (max-width: $breakpoint-lg){
          width: getVW-base-sp(200);
        }
      }
    }
    &__txt{
      font-size: getPercentFontSize-base(30);
      line-height: 1.6;
      font-weight: bold;
      margin-bottom: getVW-base(50);
      @media screen and (max-width: $breakpoint-lg){
        font-size: getPercentFontSize-base(20);
        margin-bottom: getVW-base-sp(50);
      }
    }
    &__list{
      display: flex;
      justify-content: space-between;
      &__item{
        width: getVW-base(400);
        a{
          display: block;
          color: #000;
        }
        &__th{
          margin-bottom: getVW-base(30);
          img{
            width: 100%;
            vertical-align: bottom;
          }
        }
        &__ttl{
          font-size: getPercentFontSize-base(30);
          margin-bottom: getVW-base(15);
          font-weight: bold;
        }
        &__txt{
          line-height: 1.8;
        }
      }
      @media screen and (max-width: $breakpoint-lg){
        display: block;
        &__item{
          width: 100%;
          margin-bottom: getVW-base-sp(40);
          &:last-child{
            margin-bottom: 0;
          }
          &__th{
            margin-bottom: getVW-base-sp(20);
            img{
              width: 100%;
              vertical-align: bottom;
            }
          }
          &__ttl{
            font-size: getPercentFontSize-base(20);
            margin-bottom: getVW-base-sp(10);
            font-weight: bold;
          }
          &__txt{
            font-size: getPercentFontSize-base(16);
            line-height: 1.8;
          }
        }
      }
    }
  }
}

.bx-wrapper{
  box-shadow: none;
  border: none;
  background: none;
  .bx-controls{
    padding: 0 getVW-base(50);
    position: relative;
    display: inline-block;
    @media screen and (max-width: $breakpoint-lg){
      padding: 0 getVW-base-sp(40);
    }
  }
  .bx-controls-direction{
    a{
      margin-top: 0;
      width: 24px;
      height: 30px;
    }
    .bx-prev{
      left: 0;
      background: url(../img/icon_arrow_l.png) no-repeat;
      background-size: 100% auto;
    }
    .bx-next{
      right: 0;
      background: url(../img/icon_arrow_r.png) no-repeat;
      background-size: 100% auto;
    } 
  } 
  .bx-pager{
    display: flex;
    justify-content: start;
    bottom: -18px;
    width: auto;
    position: static;
    padding-top: 30px;
    &.bx-default-pager{
      a{
        width: getVW-base(100);
        height: 6px;
        border-radius: 0;
        margin: 0;
        background-color: #D4D9D9;
        &.active, &.focus{
          background: $color-main;
        }
        @media screen and (max-width: $breakpoint-lg){
          width: getVW-base-sp(40);
        }
      }
    }
    .bx-pager-item{
      display: block;
      margin:0 getVW-base(10);
      &:last-child{
        display: none;
        @media screen and (max-width: $breakpoint-lg){
          display: block;
        }
      }
    }
  }
}
.lower-about__cts{
  .bx-wrapper{
    .bx-pager{
      bottom: -25px;
    }
  }
}
.news{
  .bx-wrapper{
    .bx-controls-direction{
      .bx-prev{
        background-image: url(../img/icon_arrow_l_w.png);
      }
      .bx-next{
        background-image: url(../img/icon_arrow_r_w.png);
      } 
    } 
    .bx-pager{
      bottom: -25px;
      &.bx-default-pager{
        a{
          //background-color: $color-main;
          &.active, &.focus{
            //background: $color-white;
          }
        }
      }
    }
  }
}
