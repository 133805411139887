//
// ブレイクポイント
// モバイルを基準 min-widthの指定
// px以上の
$breakpoint-xl: 1200px;
$breakpoint-lg: 991px;
$breakpoint-md: 767px;


// デザイン上の横幅（比率計算で使用のためpx単位なし）
$main-base-width: 1920;
$main-base-width-sp: 375;

$main-base-fontsize: 20;

//画像フォルダ
$img_path: "/img/";



//カラー設定
$color-main: #14264D;
$color-main-text: #000000;
$color-white: #ffffff;
$color-gray: #DEDEDE;
$color-line: $color-main;