@charset "UTF-8";


/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pl50 {
  padding-left: 50px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.w100 {
  width: 100%;
}

.show-sp {
  display: none;
}

@media screen and (max-width: 991px) {
  .show-sp {
    display: block;
  }
}

.show-pc {
  display: block;
}

@media screen and (max-width: 991px) {
  .show-pc {
    display: none;
  }
}

.btn-center a {
  margin: 0 auto;
}

.btn-under-arrow {
  display: block;
  border-bottom: 1px solid #FFF;
  position: relative;
}

.btn-under-arrow::after {
  position: absolute;
  right: 0;
  bottom: -1px;
  content: '';
  width: 10px;
  height: 10px;
  border-bottom: solid 1px;
  border-right: solid 1px;
  transform: skew(45deg);
}

.btn-under-arrow:hover {
  opacity: .8;
}

.btn-square-arrow {
  display: block;
  background: #ffffff;
  border: 1px solid #ffffff;
  position: relative;
  color: #000 !important;
  text-align: center;
  padding: calc(20 / 1920 * 100vw) 10% calc(20 / 1920 * 100vw) 0;
  font-size: calc(22 / 20 * 100%);
  font-weight: bold;
}

.btn-square-arrow:visited {
  color: #000 !important;
}

.btn-square-arrow::before {
  /* 三角形の表示設定 */
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6%;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid #000;
}

.btn-square-arrow:hover {
  color: #ffffff !important;
  background: #14264D;
}

.btn-square-arrow:hover::before {
  border-left: 10px solid #ffffff;
}

.btn-square-arrow.w-m {
  width: calc(420 / 1920 * 100vw);
  padding: calc(20 / 1920 * 100vw) 0 calc(20 / 1920 * 100vw) 0;
}

@media screen and (max-width: 991px) {
  .btn-square-arrow {
    padding: calc(16 / 375 * 100vw) 0 calc(16 / 375 * 100vw) 0;
    font-size: calc(18 / 20 * 100%);
  }
  .btn-square-arrow.w-m {
    width: 100%;
    padding: calc(10 / 375 * 100vw) 0 calc(10 / 375 * 100vw) 0;
  }
}

html, body {
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
  font-weight: 500;
  height: 100%;
  line-height: 1.6;
  letter-spacing: 0;
  font-size: calc(20 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  html, body {
    font-size: calc(20 / 375 * 100vw);
  }
}

body {
  height: 100%;
}

body a {
  transition: .2s ease;
  text-decoration: none;
}

.wrap {
  height: 100%;
}

.gnav-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(400 / 1920 * 100vw);
  min-height: 600px;
  height: 100%;
  background: #0C346A;
  mix-blend-mode: multiply;
}

@media screen and (max-width: 991px) {
  .gnav-bg {
    display: none;
  }
}

.gnav {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(400 / 1920 * 100vw);
  min-height: 600px;
  height: 100%;
  color: #ffffff;
}

.gnav a, .gnav a:visited {
  color: #ffffff;
}

.gnav__bg {
  position: relative;
  width: 100%;
  height: 100%;
  padding: calc(60 / 1920 * 100vw);
}

.gnav__innr {
  position: relative;
  height: 100%;
}

.gnav__ttl {
  margin-bottom: calc(60 / 1920 * 100vw);
}

.gnav__ttl img {
  width: calc(271 / 1920 * 100vw);
}

.gnav__date {
  margin-bottom: calc(60 / 1920 * 100vw);
  font-size: calc(38 / 20 * 100%);
  line-height: 1.2;
  font-weight: bold;
}

.gnav__date span {
  font-size: 64%;
}

.gnav__info {
  font-size: calc(20 / 20 * 100%);
  font-weight: bold;
  line-height: 2.2;
}

.gnav__menu {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.gnav__menu__list {
  margin-bottom: calc(60 / 1920 * 100vw);
}

.gnav__menu__list__item {
  font-size: calc(22 / 20 * 100%);
  margin-bottom: calc(34 / 1920 * 100vw);
}

.gnav__menu__link p {
  margin-top: calc(18 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .gnav {
    background: #0C346A;
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s;
    left: auto;
    right: -100%;
  }
  .gnav.panelactive {
    display: block;
    visibility: visible;
    opacity: 1;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    right: 0;
  }
  .gnav__bg {
    padding: calc(66 / 375 * 100vw) calc(50 / 375 * 100vw);
  }
  .gnav__innr {
    position: relative;
    height: 100%;
  }
  .gnav__ttl {
    margin-bottom: calc(20 / 375 * 100vw);
  }
  .gnav__ttl img {
    width: calc(217 / 375 * 100vw);
  }
  .gnav__date {
    margin-bottom: calc(20 / 375 * 100vw);
    font-size: calc(23 / 20 * 100%);
    line-height: 1.8;
    font-weight: bold;
  }
  .gnav__date span {
    font-size: 64%;
  }
  .gnav__info {
    font-size: calc(16 / 20 * 100%);
    font-weight: bold;
    line-height: 2;
  }
  .gnav__menu {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .gnav__menu__list {
    margin-bottom: calc(30 / 375 * 100vw);
  }
  .gnav__menu__list__item {
    font-size: calc(22 / 20 * 100%);
    margin-bottom: calc(20 / 375 * 100vw);
  }
  .gnav__menu__link p {
    margin-top: calc(15 / 375 * 100vw);
    font-size: calc(18 / 20 * 100%);
    width: 100%;
  }
}

@media (max-width: 991px) and (max-height: 700px) {
  .gnav__bg {
    padding: calc(40 / 375 * 100vw) calc(30 / 375 * 100vw);
  }
  .gnav__innr {
    position: relative;
    height: 100%;
  }
  .gnav__ttl {
    margin-bottom: calc(15 / 375 * 100vw);
  }
  .gnav__ttl img {
    width: calc(200 / 375 * 100vw);
  }
  .gnav__date {
    margin-bottom: calc(15 / 375 * 100vw);
    font-size: calc(20 / 20 * 100%);
    line-height: 1.6;
    font-weight: bold;
  }
  .gnav__date span {
    font-size: 64%;
  }
  .gnav__info {
    font-size: calc(16 / 20 * 100%);
    font-weight: bold;
    line-height: 1.6;
  }
  .gnav__menu {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .gnav__menu__list {
    margin-bottom: calc(20 / 375 * 100vw);
  }
  .gnav__menu__list__item {
    font-size: calc(20 / 20 * 100%);
    margin-bottom: calc(15 / 375 * 100vw);
  }
  .gnav__menu__link p {
    margin-top: calc(10 / 375 * 100vw);
    font-size: calc(16 / 20 * 100%);
    width: 100%;
  }
}

.gnav-btn {
  display: none;
}

@media screen and (max-width: 991px) {
  .gnav-btn {
    display: block;
    position: fixed;
    top: calc(20 / 375 * 100vw);
    right: calc(20 / 375 * 100vw);
    z-index: 100;
  }
  .gnav-btn img {
    width: calc(40 / 375 * 100vw);
  }
  .gnav-btn__open {
    display: block;
  }
  .gnav-btn__close {
    display: none;
  }
  .gnav-btn.panelactive .gnav-btn__open {
    display: none;
  }
  .gnav-btn.panelactive .gnav-btn__close {
    display: block;
  }
}

.cts {
  margin-left: calc(400 / 1920 * 100vw);
  padding: calc(120 / 1920 * 100vw) 0 calc(120 / 1920 * 100vw) calc(50 / 1920 * 100vw);
}

.cts.lower {
  padding: calc(120 / 1920 * 100vw) 0 calc(120 / 1920 * 100vw) calc(100 / 1920 * 100vw);
}

.cts__innr {
  display: flex;
}

.cts__ttl {
  width: calc(250 / 1920 * 100vw);
  position: relative;
}

.cts__ttl img {
  position: absolute;
  top: 0;
  left: calc(112 / 1920 * 100vw);
  width: auto;
  height: calc(112 / 1920 * 100vw);
  transform: rotateZ(90deg);
  transform-origin: top left;
}

.cts__body {
  flex: 1;
}

.cts__body__innr {
  padding-right: calc(100 / 1920 * 100vw);
}

.cts__body__innr.rightspace0 {
  padding-right: 0;
}

@media screen and (max-width: 991px) {
  .cts {
    margin-left: 0;
    padding: calc(40 / 375 * 100vw) calc(20 / 375 * 100vw);
  }
  .cts.lower {
    padding: calc(60 / 375 * 100vw) calc(20 / 375 * 100vw);
  }
  .cts__innr {
    display: block;
  }
  .cts__ttl {
    width: auto;
    position: static;
    margin-bottom: calc(40 / 375 * 100vw);
  }
  .cts__ttl img {
    position: static;
    width: auto;
    height: calc(35 / 375 * 100vw);
    transform: rotateZ(0deg);
  }
  .cts__body__innr {
    padding-right: 0;
  }
}

.mv {
  background-color: #14264D;
  height: 100%;
}

.mv.top {
  background: url(../img/bg_mv.jpg) no-repeat center center;
  background-size: cover;
}

.mv .cts {
  height: 100%;
}

.mv__innr {
  width: 100%;
  height: 100%;
  position: relative;
}

.mv__ttl img {
  width: calc(1112 / 1920 * 100vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mv__info {
  display: none;
}

@media screen and (max-width: 991px) {
  .mv.top {
    background: url(../img/bg_mv_sp.jpg) no-repeat center center;
    background-size: cover;
  }
  .mv__ttl img {
    width: 100vw;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, 0%);
  }
  .mv__info {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #ffffff;
  }
  .mv__info__ttl {
    margin-bottom: calc(10 / 375 * 100vw);
  }
  .mv__info__date {
    font-size: calc(27 / 20 * 100%);
  }
  .mv__info__date span {
    font-size: 60%;
  }
  .mv__info__data {
    font-size: calc(18 / 20 * 100%);
  }
}

.about {
  background-color: #14264D;
}

.about__ttl img {
  width: calc(446 / 1920 * 100vw);
  height: auto;
  margin-bottom: calc(100 / 1920 * 100vw);
}

.about__txt {
  font-size: calc(27 / 20 * 100%);
  line-height: 1.8;
  color: #ffffff;
  letter-spacing: 0em;
  margin-bottom: calc(100 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .about__ttl img {
    width: calc(291 / 375 * 100vw);
    margin-bottom: calc(40 / 375 * 100vw);
  }
  .about__txt {
    font-size: calc(20 / 20 * 100%);
    margin-bottom: calc(40 / 375 * 100vw);
  }
}

.program__ttl {
  display: inline-block;
  border-top: calc(19 / 1920 * 100vw) solid #14264D;
  padding: calc(42 / 1920 * 100vw) calc(5 / 1920 * 100vw) 0;
  margin-bottom: calc(60 / 1920 * 100vw);
  color: #14264D;
}

.program__ttl img {
  width: calc(387 / 1920 * 100vw);
}

.program__schedule {
  margin-bottom: calc(100 / 1920 * 100vw);
}

.program__schedule__list__item {
  width: calc(420 / 1920 * 100vw) !important;
  margin-right: calc(50 / 1920 * 100vw);
  cursor: grab;
}

.program__schedule__list__item:active {
  cursor: grabbing;
}

.program__schedule__list__item__date {
  font-size: calc(24 / 20 * 100%);
  font-weight: bold;
  margin-bottom: calc(20 / 1920 * 100vw);
}

.program__schedule__list__item__ttl {
  font-size: calc(30 / 20 * 100%);
  font-weight: bold;
  height: calc(170 / 1920 * 100vw);
}

.program__schedule__list__item__txt {
  height: calc(190 / 1920 * 100vw);
}

.program__schedule__list__item__speaker {
  margin-bottom: calc(20 / 1920 * 100vw);
}

.program__schedule__list__item__speaker img {
  width: calc(103 / 1920 * 100vw);
}

.program__schedule__list__item__profile {
  display: flex;
  margin-bottom: calc(20 / 1920 * 100vw);
}

.program__schedule__list__item__profile__th {
  width: calc(200 / 1920 * 100vw);
}

.program__schedule__list__item__profile__th img {
  width: 100%;
}

.program__schedule__list__item__profile__name {
  padding: 0 0 0 calc(20 / 1920 * 100vw);
  font-size: calc(20 / 20 * 100%);
  flex: 1;
}

.program__schedule__list__item__profile__name span {
  display: inline-block;
  font-size: 80%;
  line-height: 1.4;
  margin-bottom: 3px;
}

.program__schedule__list__item__profile__place {
  font-size: calc(16 / 20 * 100%);
  display: inline-block;
  padding: 0 5px;
  color: #ffffff;
  background: #14264D;
  margin-bottom: 5px;
}

@media screen and (max-width: 991px) {
  .program__ttl {
    display: inline-block;
    border-top: calc(19 / 375 * 100vw) solid #14264D;
    padding: calc(28 / 375 * 100vw) calc(5 / 375 * 100vw) 0;
    margin-bottom: calc(30 / 375 * 100vw);
    margin-left: calc(-20 / 375 * 100vw);
  }
  .program__ttl img {
    width: calc(278 / 375 * 100vw);
  }
  .program__schedule {
    margin-bottom: calc(76 / 375 * 100vw);
  }
  .program__schedule__list__item {
    width: calc(290 / 375 * 100vw) !important;
    margin-right: calc(20 / 375 * 100vw);
  }
  .program__schedule__list__item__date {
    font-size: calc(18 / 20 * 100%);
    margin-bottom: calc(20 / 1920 * 100vw);
  }
  .program__schedule__list__item__ttl {
    font-size: calc(22 / 20 * 100%);
    height: calc(130 / 375 * 100vw);
  }
  .program__schedule__list__item__txt {
    font-size: calc(18 / 20 * 100%);
    height: calc(220 / 375 * 100vw);
  }
  .program__schedule__list__item__speaker {
    margin-bottom: calc(10 / 375 * 100vw);
  }
  .program__schedule__list__item__speaker img {
    width: calc(90 / 375 * 100vw);
  }
  .program__schedule__list__item__profile {
    margin-bottom: calc(15 / 375 * 100vw);
  }
  .program__schedule__list__item__profile__th {
    width: calc(130 / 375 * 100vw);
  }
  .program__schedule__list__item__profile__name {
    padding: 0 0 0 calc(20 / 375 * 100vw);
    font-size: calc(12 / 20 * 100%);
  }
  .program__schedule__list__item__profile__name span {
    display: inline-block;
    font-size: 80%;
  }
  .program__schedule__list__item__profile__place {
    font-size: calc(16 / 20 * 100%);
    display: inline-block;
    padding: 3px 8px;
    color: #ffffff;
    background: #14264D;
    margin-bottom: 5px;
  }
}

.news {
  background: url(../img/bg_news.jpg) no-repeat center center;
  background-size: cover;
}

.news__list {
  zoom: 1;
}

.news__list:before, .news__list:after {
  content: "";
  display: table;
}

.news__list:after {
  clear: both;
}

.news__list-wrap {
  margin-bottom: calc(100 / 1920 * 100vw);
}

.news__list__item {
  width: calc(420 / 1920 * 100vw) !important;
  margin-right: calc(50 / 1920 * 100vw);
  float: left;
  cursor: grab;
}

.news__list__item a, .news__list__item a:visited {
  color: #ffffff;
}

.news__list__item:active {
  cursor: grabbing;
}

.news__list__item__th {
  margin-bottom: calc(30 / 1920 * 100vw);
}

.news__list__item__th img {
  width: 100%;
  vertical-align: bottom;
}

@media screen and (max-width: 991px) {
  .news__list-wrap {
    margin-bottom: calc(40 / 375 * 100vw);
  }
  .news__list__item {
    width: calc(290 / 375 * 100vw) !important;
    margin-right: calc(20 / 375 * 100vw);
    float: left;
    cursor: grab;
  }
  .news__list__item__th {
    margin-bottom: calc(26 / 375 * 100vw);
  }
  .news__list__item__th img {
    width: 100%;
  }
  .news__list__item__ttl p {
    font-size: calc(18 / 20 * 100%);
  }
}

.footer {
  background: url(../img/bg_footer.jpg) no-repeat center center;
  background-size: cover;
  color: #ffffff;
}

.footer .cts {
  padding-bottom: calc(80 / 1920 * 100vw);
}

.footer__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__ttl {
  font-size: calc(30 / 20 * 100%);
  font-weight: bold;
  margin-bottom: calc(80 / 1920 * 100vw);
}

.footer__logo {
  margin-bottom: calc(60 / 1920 * 100vw);
}

.footer__logo img {
  width: calc(88 / 1920 * 100vw);
}

.footer__txt-about {
  margin-bottom: calc(60 / 1920 * 100vw);
}

.footer__txt-dips {
  margin-bottom: calc(100 / 1920 * 100vw);
  width: calc(640 / 1920 * 100vw);
  line-height: 2;
}

.footer__txt-sponcor {
  text-align: center;
}

@media screen and (max-width: 991px) {
  .footer .cts {
    padding-bottom: calc(65 / 375 * 100vw);
  }
  .footer__ttl {
    font-size: calc(20 / 20 * 100%);
    font-weight: bold;
    margin-bottom: calc(40 / 375 * 100vw);
  }
  .footer__logo {
    margin-bottom: calc(40 / 375 * 100vw);
  }
  .footer__logo img {
    width: calc(88 / 375 * 100vw);
  }
  .footer__txt-about {
    font-size: calc(16 / 20 * 100%);
    margin-bottom: calc(60 / 1920 * 100vw);
  }
  .footer__txt-dips {
    font-size: calc(16 / 20 * 100%);
    margin-bottom: calc(40 / 375 * 100vw);
    width: calc(309 / 375 * 100vw);
    line-height: 2;
  }
  .footer__txt-sponcor {
    font-size: calc(16 / 20 * 100%);
  }
}

.lower-about__mv {
  color: #ffffff;
  height: auto !important;
}

.lower-about__mv__ttl {
  font-size: calc(80 / 20 * 100%);
  margin-bottom: calc(80 / 1920 * 100vw);
}

.lower-about__mv p {
  font-size: calc(30 / 20 * 100%);
  line-height: 1.8;
  margin-bottom: 2.4rem;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .lower-about__mv__ttl {
    font-size: calc(40 / 20 * 100%);
    margin-bottom: calc(30 / 375 * 100vw);
  }
  .lower-about__mv p {
    font-size: calc(17 / 20 * 100%);
    line-height: 1.8;
    margin-bottom: 1.6rem;
    font-weight: bold;
  }
}

.lower-about__cts {
  border-bottom: 1px solid #D4D9D9;
}

.lower-about__cts p {
  line-height: 1.8;
}

.lower-about__cts__ttl {
  margin-bottom: calc(60 / 1920 * 100vw);
}

.lower-about__cts__ttl img {
  vertical-align: bottom;
}

.lower-about__cts__subttl {
  margin-bottom: calc(60 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__cts p {
    line-height: 1.8;
  }
  .lower-about__cts__ttl {
    margin-bottom: calc(50 / 375 * 100vw);
  }
  .lower-about__cts__subttl {
    margin-bottom: calc(20 / 375 * 100vw);
  }
}

.lower-about__try__ttl img {
  width: calc(446 / 1920 * 100vw);
}

.lower-about__try__txt1 {
  line-height: 2 !important;
  margin-bottom: calc(60 / 1920 * 100vw);
}

.lower-about__try__txt2 {
  line-height: 2 !important;
  display: inline-block;
  text-align: left;
}

@media screen and (max-width: 991px) {
  .lower-about__try__ttl img {
    width: calc(221 / 375 * 100vw);
  }
  .lower-about__try__txt1 {
    margin-bottom: calc(40 / 375 * 100vw);
    font-size: calc(16 / 20 * 100%);
  }
  .lower-about__try__txt2 {
    line-height: 2 !important;
    display: inline-block;
    text-align: right;
    font-size: calc(13 / 20 * 100%);
  }
}

.lower-about__watch__ttl img {
  width: calc(571 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__watch__ttl img {
    width: calc(292 / 375 * 100vw);
  }
}

.lower-about__watch__list {
  padding-left: 1.5rem;
}

.lower-about__watch__list__item {
  list-style-type: decimal;
  list-style-position: outside;
  font-size: calc(30 / 20 * 100%);
  margin-bottom: calc(40 / 1920 * 100vw);
  font-weight: bold;
}

.lower-about__watch__list__item:last-child {
  margin-bottom: calc(0 / 1920 * 100vw);
}

.lower-about__watch__list__item span {
  margin-top: calc(5 / 1920 * 100vw);
  display: block;
  font-size: 66%;
  font-weight: normal;
}

@media screen and (max-width: 991px) {
  .lower-about__watch__list {
    padding-left: 1.3rem;
  }
  .lower-about__watch__list__item {
    font-size: calc(20 / 20 * 100%);
    margin-bottom: calc(20 / 375 * 100vw);
  }
  .lower-about__watch__list__item span {
    margin-top: calc(5 / 375 * 100vw);
    font-size: 80%;
  }
}

.lower-about__archive__ttl img {
  width: calc(331 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__archive__ttl img {
    width: calc(207 / 375 * 100vw);
  }
}

.lower-about__archive__subttl img {
  width: calc(134 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__archive__subttl img {
    width: calc(69 / 375 * 100vw);
  }
}

.lower-about__archive .news__list-wrap.mb0 {
  margin-bottom: 0 !important;
}

.lower-about__archive .news__list__item a {
  color: #000 !important;
}

.lower-about__archive .news__list__item a:visited {
  color: #000 !important;
}

@media screen and (max-width: 991px) {
  .lower-about__archive .news__list__item__th {
    margin-bottom: calc(20 / 375 * 100vw);
  }
}

@media screen and (max-width: 991px) {
  .lower-about__archive .news__list__item__ttl p {
    font-size: calc(16 / 20 * 100%);
  }
}

.lower-about__background__ttl img {
  width: calc(530 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__background__ttl img {
    width: calc(331 / 375 * 100vw);
  }
}

.lower-about__background__subttl {
  font-size: calc(30 / 20 * 100%);
  margin-bottom: calc(50 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__background__subttl {
    font-size: calc(24 / 20 * 100%);
    margin-bottom: calc(40 / 375 * 100vw);
  }
}

.lower-about__background__txt {
  margin-bottom: calc(60 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__background__txt {
    font-size: calc(16 / 20 * 100%);
    margin-bottom: calc(50 / 375 * 100vw);
  }
}

.lower-about__background__col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(100 / 1920 * 100vw);
}

.lower-about__background__col:last-child {
  margin-bottom: 0;
}

.lower-about__background__col__txt {
  width: calc(400 / 1920 * 100vw);
}

.lower-about__background__col__txt__ttl {
  font-size: calc(30 / 20 * 100%);
  font-weight: normal;
  margin-bottom: calc(50 / 1920 * 100vw);
}

.lower-about__background__col__txt__body {
  margin-bottom: calc(50 / 1920 * 100vw);
}

.lower-about__background__col__txt__source {
  font-size: calc(14 / 20 * 100%);
}

.lower-about__background__col__graph img {
  width: calc(869 / 1920 * 100vw);
}

.lower-about__background__col__graph img.graph2 {
  width: calc(843 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__background__col {
    display: block;
    margin-bottom: calc(60 / 375 * 100vw);
  }
  .lower-about__background__col__txt {
    width: 100%;
  }
  .lower-about__background__col__txt__ttl {
    font-size: calc(24 / 20 * 100%);
    font-weight: normal;
    margin-bottom: calc(30 / 375 * 100vw);
  }
  .lower-about__background__col__txt__body {
    font-size: calc(16 / 20 * 100%);
    margin-bottom: calc(30 / 375 * 100vw);
  }
  .lower-about__background__col__txt__source {
    font-size: calc(12 / 20 * 100%);
  }
  .lower-about__background__col__graph {
    margin-bottom: calc(20 / 375 * 100vw);
  }
  .lower-about__background__col__graph img {
    width: 100%;
  }
  .lower-about__background__col__graph img.graph2 {
    width: 100%;
  }
}

.lower-about__joinus__ttl img {
  width: calc(316 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__joinus__ttl img {
    width: calc(200 / 375 * 100vw);
  }
}

.lower-about__joinus__txt {
  font-size: calc(30 / 20 * 100%);
  line-height: 1.6;
  font-weight: bold;
  margin-bottom: calc(50 / 1920 * 100vw);
}

@media screen and (max-width: 991px) {
  .lower-about__joinus__txt {
    font-size: calc(20 / 20 * 100%);
    margin-bottom: calc(50 / 375 * 100vw);
  }
}

.lower-about__joinus__list {
  display: flex;
  justify-content: space-between;
}

.lower-about__joinus__list__item {
  width: calc(400 / 1920 * 100vw);
}

.lower-about__joinus__list__item a {
  display: block;
  color: #000;
}

.lower-about__joinus__list__item__th {
  margin-bottom: calc(30 / 1920 * 100vw);
}

.lower-about__joinus__list__item__th img {
  width: 100%;
  vertical-align: bottom;
}

.lower-about__joinus__list__item__ttl {
  font-size: calc(30 / 20 * 100%);
  margin-bottom: calc(15 / 1920 * 100vw);
  font-weight: bold;
}

.lower-about__joinus__list__item__txt {
  line-height: 1.8;
}

@media screen and (max-width: 991px) {
  .lower-about__joinus__list {
    display: block;
  }
  .lower-about__joinus__list__item {
    width: 100%;
    margin-bottom: calc(40 / 375 * 100vw);
  }
  .lower-about__joinus__list__item:last-child {
    margin-bottom: 0;
  }
  .lower-about__joinus__list__item__th {
    margin-bottom: calc(20 / 375 * 100vw);
  }
  .lower-about__joinus__list__item__th img {
    width: 100%;
    vertical-align: bottom;
  }
  .lower-about__joinus__list__item__ttl {
    font-size: calc(20 / 20 * 100%);
    margin-bottom: calc(10 / 375 * 100vw);
    font-weight: bold;
  }
  .lower-about__joinus__list__item__txt {
    font-size: calc(16 / 20 * 100%);
    line-height: 1.8;
  }
}

.bx-wrapper {
  box-shadow: none;
  border: none;
  background: none;
}

.bx-wrapper .bx-controls {
  padding: 0 calc(50 / 1920 * 100vw);
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .bx-wrapper .bx-controls {
    padding: 0 calc(40 / 375 * 100vw);
  }
}

.bx-wrapper .bx-controls-direction a {
  margin-top: 0;
  width: 24px;
  height: 30px;
}

.bx-wrapper .bx-controls-direction .bx-prev {
  left: 0;
  background: url(../img/icon_arrow_l.png) no-repeat;
  background-size: 100% auto;
}

.bx-wrapper .bx-controls-direction .bx-next {
  right: 0;
  background: url(../img/icon_arrow_r.png) no-repeat;
  background-size: 100% auto;
}

.bx-wrapper .bx-pager {
  display: flex;
  justify-content: start;
  bottom: -18px;
  width: auto;
  position: static;
  padding-top: 30px;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  width: calc(100 / 1920 * 100vw);
  height: 6px;
  border-radius: 0;
  margin: 0;
  background-color: #D4D9D9;
}

.bx-wrapper .bx-pager.bx-default-pager a.active, .bx-wrapper .bx-pager.bx-default-pager a.focus {
  background: #14264D;
}

@media screen and (max-width: 991px) {
  .bx-wrapper .bx-pager.bx-default-pager a {
    width: calc(40 / 375 * 100vw);
  }
}

.bx-wrapper .bx-pager .bx-pager-item {
  display: block;
  margin: 0 calc(10 / 1920 * 100vw);
}

.bx-wrapper .bx-pager .bx-pager-item:last-child {
  display: none;
}

@media screen and (max-width: 991px) {
  .bx-wrapper .bx-pager .bx-pager-item:last-child {
    display: block;
  }
}

.lower-about__cts .bx-wrapper .bx-pager {
  bottom: -25px;
}

.news .bx-wrapper .bx-controls-direction .bx-prev {
  background-image: url(../img/icon_arrow_l_w.png);
}

.news .bx-wrapper .bx-controls-direction .bx-next {
  background-image: url(../img/icon_arrow_r_w.png);
}

.news .bx-wrapper .bx-pager {
  bottom: -25px;
}
